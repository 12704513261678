import React from 'react';
import PropTypes from 'prop-types';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import CircularProgress from '@mui/material/CircularProgress';
import styled from '@emotion/styled';

// Styled components using @emotion
const StyledCard = styled(Card)({
  maxWidth: 500,
  margin: '5px',
});

const StyledCardMedia = styled(CardMedia)({
  height: 350,
});

const Container = styled('div')({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'wrap',
  justifyContent: 'space-around',
  padding: '1px',
});

class WebcamModule extends React.Component {
  state = {
    images: {
      cam1: null,
      cam2: null,
      cam3: null,
    },
  };

  componentDidMount() {
    this.fetchImages();
    this.interval = setInterval(this.fetchImages, 5000); // Changed to 5000ms for practicality
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  fetchImages = async () => {
    const cams = Object.keys(this.state.images);
    for (const cam of cams) {
      try {
        const response = await fetch(`api/webcam/${cam}`);
        if (!response.ok) {
          throw new Error(`Response error for camera ${cam}: ${response.statusText}`);
        }
        const blob = await response.blob();
        this.setState(prevState => ({
          images: {
            ...prevState.images,
            [cam]: URL.createObjectURL(blob),
          },
        }));
      } catch (error) {
        console.error('Error fetching image for camera', cam, error);
        // Handle error state appropriately here if necessary
      }
    }
  };

  render() {
    const { images } = this.state;

    return (
      <Container>
        {Object.entries(images).map(([camId, imageUrl]) => (
          <StyledCard key={camId}>
            {imageUrl ? (
              <StyledCardMedia
                component="img"
                image={imageUrl}
                title={`Camera ${camId}`}
                alt={`Live feed from camera ${camId}`}
              />
            ) : (
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 350 }}>
                <CircularProgress />
              </div>
            )}
          </StyledCard>
        ))}
      </Container>
    );
  }
}

WebcamModule.propTypes = {
  images: PropTypes.objectOf(PropTypes.string),
};

export default WebcamModule;
