// App.jsx
import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate } from 'react-router-dom';
import { initializeApp } from 'firebase/app';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import firebaseConfig from './firebaseConfig';
import Login from './components/Authentication';
import Dashboard from './components/Dashboard';

// Initialize Firebase
initializeApp(firebaseConfig);

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/dashboard" element={<PrivateRoute><Dashboard /></PrivateRoute>} />
      </Routes>
    </Router>
  );
}

function PrivateRoute({ children }) {
  const [isAuthenticated, setIsAuthenticated] = useState(null);
  const auth = getAuth();

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setIsAuthenticated(!!user); // Set to true if user is not null, otherwise false
    });

    // Clean up the subscription on unmount
    return () => unsubscribe();
  }, [auth]);

  if (isAuthenticated === null) {
    return <div>Loading...</div>; // Show a loading message while authentication status is being determined
  }

  return isAuthenticated ? children : <Navigate to="/" replace />;
}

export default App;
