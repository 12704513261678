import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';

const weatherConditions = [
    { img: 'AAG_ImageWindCond.png', title: 'Wind Speed' },
    { img: 'AAG_ImageCloudCondition.png', title: 'Cloud Cover' },
    { img: 'AAG_ImageHumidityCondition.png', title: 'Moisture' },
    { img: 'AAG_ImageDayCondition.png', title: 'Brightness' },
    { img: 'AAG_ImageTemperature.png', title: 'Ambient Temperature' },
    // ... Add others as needed
];

const styles = {
    dashboardModule: {
        // Any general styles for the main dashboard module can go here
    },
    weatherImageList: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    weatherCondition: {
        display: 'inline-block',
        margin: '10px',
        textAlign: 'center'
    },
    weatherImage: {
        display: 'block',
        margin: '0 auto'
    },
    card: {
        margin: '10px',
        padding: '10px',
        flexGrow: 1,
        width: '100%' // Adjusted width
    },
};

class WeatherModule extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            isLoading: true
            // Other state properties can be initialized here
        };
    }

    componentDidMount() {
        this.fetchWeatherData();
        this.interval = setInterval(() => {
            this.fetchWeatherData();
        }, 10000); // 10 seconds interval
    }

    componentWillUnmount() {
        clearInterval(this.interval);
    }

    fetchWeatherData() {
        fetch("api/get-weather")
            .then(response => response.json())
            .then(data => {
                this.setState({
                    ...data,
                    isLoading: false
                });
            })
            .catch(error => console.error("Error fetching weather data:", error));
    }

    celsiusToFahrenheit(celsius) {
        return (celsius * 9/5) + 32;
    }

    kmhToMph(kmh) {
        return kmh * 0.621371;
    }

    getCloudStatus(cloudValue) {
        if (cloudValue <= -5) return "Clear";
        if (cloudValue <= 0) return "Cloudy";
        if (cloudValue <= 30) return "Overcast";
        return "Unknown";
    }

    getRainStatus(rainValue) {
        if (rainValue >= 3200) return "Dry";
        if (rainValue >= 2800) return "Wet";
        if (rainValue >= 0) return "Rain";
        return "Unknown";
    }

    getWindStatus(windValue) {
        if (windValue <= 5) return "Calm";
        if (windValue <= 10) return "Windy";
        if (windValue <= 999) return "Very Windy";
        return "Unknown";
    }

    getBrightnessStatus(lightValue) {
        if (lightValue >= 2100) return "Dark";
        if (lightValue >= 6) return "Light";
        if (lightValue >= 0) return "Very Light";
        return "Unknown";
    }

    getHumidityStatus(humidityValue) {
        if (humidityValue <= 50) return "Dry";
        if (humidityValue <= 80) return "Normal";
        if (humidityValue <= 100) return "Humid";
        return "Unknown";
    }

    getPressureStatus(pressureValue) {
        if (pressureValue <= 980) return "Low";
        if (pressureValue <= 1030) return "Normal";
        if (pressureValue <= 1200) return "High";
        return "Unknown";
    }

    render() {
        if (this.state.isLoading) {
            return (
                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}>
                    <CircularProgress />
                </div>
            );
        }

        return (
            <div className="weather_module">
                <h1>Weather Status</h1>
                <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Card style={styles.card}>
                            <CardContent>
                                <p>Date and Time: {this.state.dateLocalTime}</p>
                                <p>Temperature: {this.state.temp}°C / {this.celsiusToFahrenheit(this.state.temp).toFixed(2)}°F</p>
                                <p>Clouds: {this.state.clouds}</p>
                                <p>Rain: {this.state.rain}</p>
                                <p>Wind: {this.state.wind} km/h / {this.kmhToMph(this.state.wind).toFixed(2)} mph</p>
                                <p>Brightness: {this.state.light}</p>
                                <p>Humidity: {this.state.hum}%</p>
                                <p>Pressure: {this.state.relpress} hPa</p>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid item xs={12} sm={6} md={6} lg={6}>
                        <Card style={styles.card}>
                            <CardContent>
                                <p>Clouds: <span style={{color: this.getCloudStatus(this.state.clouds) === "Clear" ? "green" : (this.getCloudStatus(this.state.clouds) === "Cloudy" ? "orange" : "red")}}>{this.getCloudStatus(this.state.clouds)}</span></p>
                                <p>Rain: <span style={{color: this.getRainStatus(this.state.rain) === "Dry" ? "green" : (this.getRainStatus(this.state.rain) === "Wet" ? "orange" : "red")}}>{this.getRainStatus(this.state.rain)}</span></p>
                                <p>Wind: <span style={{color: this.getWindStatus(this.state.wind) === "Calm" ? "green" : (this.getWindStatus(this.state.wind) === "Windy" ? "orange" : "red")}}>{this.getWindStatus(this.state.wind)}</span></p>
                                <p>Brightness: <span style={{color: this.getBrightnessStatus(this.state.light) === "Dark" ? "green" : (this.getBrightnessStatus(this.state.light) === "Light" ? "orange" : "red")}}>{this.getBrightnessStatus(this.state.light)}</span></p>
                                <p>Humidity: <span style={{color: this.getHumidityStatus(this.state.hum) === "Dry" ? "green" : (this.getHumidityStatus(this.state.hum) === "Normal" ? "orange" : "red")}}>{this.getHumidityStatus(this.state.hum)}</span></p>
                                <p>Pressure: <span style={{color: this.getPressureStatus(this.state.relpress) === "Low" ? "green" : (this.getPressureStatus(this.state.relpress) === "Normal" ? "orange" : "red")}}>{this.getPressureStatus(this.state.relpress)}</span></p>
                            </CardContent>
                        </Card>
                    </Grid>
                </Grid>

                <div style={styles.weatherImageList}>
                    {weatherConditions.map((condition, index) => (
                        <div key={index} style={styles.weatherCondition}>
                            <p>{condition.title}</p>
                            <img 
                                src={`images/${condition.img}`} 
                                alt={`Weather Condition - ${condition.title}`} 
                                style={styles.weatherImage}
                            />
                        </div>
                    ))}
                </div>
            </div>
        );
    }
}

export default WeatherModule;
