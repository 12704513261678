// firebaseConfig.js
const firebaseConfig = {
  apiKey: "AIzaSyCEBRqQ8rC1Zl6JRB6cna8oVwR_vt4uOwY",
  authDomain: "missioncontrol-5083a.firebaseapp.com",
  databaseURL: "https://missioncontrol-5083a.firebaseio.com",
  projectId: "missioncontrol-5083a",
  storageBucket: "missioncontrol-5083a.appspot.com",
  messagingSenderId: "377634391768"
};

export default firebaseConfig;