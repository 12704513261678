import React, { useState, useEffect } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import ListItem from '@mui/material/ListItem';
import Switch from '@mui/material/Switch';
import Button from '@mui/material/Button';

function LightModule() {
  const [relayUnits, setRelayUnits] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [operationInProgress, setOperationInProgress] = useState('');

  const fetchData = async () => {
    try {
      const response = await fetch('api/light-status');
      if (!response.ok) {
        throw new Error(`HTTP error! Status: ${response.status}`);
      }
      const data = await response.json();

      const units = Object.keys(data)
        .map((key) => ({
          id: key,
          ...data[key],
        }))
        .filter((unit) => unit.Name);

      setRelayUnits(units);
      setLoading(false);
    } catch (error) {
      setError(error);
      setLoading(false);
    }
  };

  const toggleLightStatus = async (unitId, newState) => {
    setRelayUnits((prevUnits) =>
      prevUnits.map((unit) =>
        unit.id === unitId ? { ...unit, Status: newState } : unit
      )
    );

    try {
      const response = await fetch(
        `api/turn-${newState.toLowerCase()}-specific-light/${unitId}`
      );

      if (!response.ok) {
        throw new Error('Server error');
      }
    } catch (error) {
      console.error('Network error:', error);
      setRelayUnits((prevUnits) =>
        prevUnits.map((unit) =>
          unit.id === unitId ? { ...unit, Status: newState === 'ON' ? 'OFF' : 'ON' } : unit
        )
      );
    }
  };

  const turnAllLightsOn = async () => {
    setOperationInProgress('turnOn');
    try {
      const promises = relayUnits.map(unit =>
        fetch(`api/turn-on-specific-light/${unit.id}`)
      );
      await Promise.all(promises);

      setRelayUnits(prevUnits => prevUnits.map(unit => ({ ...unit, Status: 'ON' })));
    } catch (error) {
      console.error('Error turning all lights on:', error);
    }
    setOperationInProgress('');
  };

  const turnAllLightsOff = async () => {
    setOperationInProgress('turnOff');
    try {
      const promises = relayUnits.map(unit =>
        fetch(`api/turn-off-specific-light/${unit.id}`)
      );
      await Promise.all(promises);

      setRelayUnits(prevUnits => prevUnits.map(unit => ({ ...unit, Status: 'OFF' })));
    } catch (error) {
      console.error('Error turning all lights off:', error);
    }
    setOperationInProgress('');
  };

  const observeLights = async () => {
    const onIds = ["1", "2", "5", "8", "19", "21", "23"];
    setOperationInProgress('observe');
    try {
      const promises = relayUnits
        .filter(unit => onIds.includes(unit.id))
        .map(unit => {
          const newState = unit.Status === 'ON' ? 'OFF' : 'ON';
          return fetch(`api/turn-${newState.toLowerCase()}-specific-light/${unit.id}`);
        });

      await Promise.all(promises);

      setRelayUnits(prevUnits => prevUnits.map(unit => {
        if (onIds.includes(unit.id)) {
          return { ...unit, Status: unit.Status === 'ON' ? 'OFF' : 'ON' };
        }
        return unit;
      }));
    } catch (error) {
      console.error('Error toggling lights:', error);
    }
    setOperationInProgress('');
  };

  useEffect(() => {
    fetchData();

    const intervalId = setInterval(fetchData, 10000);

    return () => {
      clearInterval(intervalId);
    };
  }, []);

  const buttonContainerStyle = {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: '20px',
  };

  const buttonStyle = {
    marginRight: '10px',
  };

  const darkBlueButtonStyle = {
    backgroundColor: '#003366',
    color: 'white',
  };

  if (loading) return <CircularProgress />;
  if (error) return <div>Error: {error.message}</div>;

  return (
    <div>
      <div style={buttonContainerStyle}>
        <Button
          style={operationInProgress === 'turnOn' ? { ...buttonStyle, opacity: 0.5 } : buttonStyle}
          variant="contained"
          color="primary"
          onClick={turnAllLightsOn}
          disabled={operationInProgress !== ''}
        >
          All Lights On
        </Button>
        <Button
          style={operationInProgress === 'turnOff' ? { ...buttonStyle, ...darkBlueButtonStyle, opacity: 0.5 } : { ...buttonStyle, ...darkBlueButtonStyle }}
          variant="contained"
          onClick={turnAllLightsOff}
          disabled={operationInProgress !== ''}
        >
          All Lights Off
        </Button>
        <Button
          variant="outlined"
          onClick={observeLights}
          disabled={operationInProgress !== ''}
        >
          Observing Lights
        </Button>
      </div>
      {relayUnits.map((unit) => (
        <div key={unit.id}>
          <ListItem>
            <Switch
              checked={unit.Status === 'ON'}
              color="primary"
              inputProps={{ 'aria-label': 'status' }}
              onChange={() => toggleLightStatus(unit.id, unit.Status === 'ON' ? 'OFF' : 'ON')}
            />
            <span>{unit.Name}</span>
          </ListItem>
        </div>
      ))}
    </div>
  );
}

export default LightModule;
