import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import ListItemIcon from '@mui/material/ListItemIcon';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


class PowerComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            data: null,
            loading: true,
            error: null
        };
    }

    componentDidMount() {
        fetch('api/pdu-status')
            .then(response => {
                if (!response.ok) {
                    throw new Error(`HTTP error! Status: ${response.status}`);
                }
                return response.json();
            })
            .then(data => {
                this.setState({ data: data, loading: false });
            })
            .catch(error => {
                this.setState({ error: error, loading: false });
            });
    }

    render() {
        const { data, loading, error } = this.state;

        if (loading) return <CircularProgress />;
        if (error) return <div>Error: {error.message}</div>;

        return (
            <div>
                {data && data.devices.map((device, index) => (
                    <div key={index}>
                        <h2>{device.type}</h2>
                        <List>
                            {Object.entries(device.ports).map(([portName, portStatus], idx) => (
                                <ListItem key={idx}>
                                    <ListItemIcon>
                                        {portStatus ? <CheckCircleOutlineIcon style={{ color: 'green' }} /> : <HighlightOffIcon style={{ color: 'red' }} />}
                                    </ListItemIcon>
                                    <ListItemText primary={portName} />
                                    <Unit name={portName} status={portStatus ? "On" : "Off"} />
                                </ListItem>
                            ))}
                        </List>
                    </div>
                ))}
            </div>
        );
    }
}

class Unit extends React.Component {
    render() {
        return (
            <div className="unit-status">
                <p>Status: {this.props.status}</p>
            </div>
        )
    }
}

export default PowerComponent;
