import * as React from 'react';
import { styled, createTheme, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import MuiDrawer from '@mui/material/Drawer';
import Box from '@mui/material/Box';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Container from '@mui/material/Container';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem'; 
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

// Import icons for your modules
import DashboardIcon from '@mui/icons-material/Dashboard';
import WbSunnyIcon from '@mui/icons-material/WbSunny'; // For Lights
import PowerIcon from '@mui/icons-material/Power';
import ImageIcon from '@mui/icons-material/Image';
import CloudIcon from '@mui/icons-material/Cloud';
import CameraAltIcon from '@mui/icons-material/CameraAlt'; // For Webcam

// Import your module components
import DashboardModule from '../modules/dashboardModule.jsx';
import WeatherModule from '../modules/weatherModule.jsx';
import LightModule from '../modules/lightModule.jsx';
import PowerModule from '../modules/powerModule.jsx';
import ImagesModule from '../modules/imagesModule.jsx';
import WebcamModule from '../modules/webcamModule.jsx';
import { getAuth, signOut } from 'firebase/auth';

// Import the logo image
import logoImage from '../images/ologo.png'; // Ensure this path is correct

const drawerWidth = 240;

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    '& .MuiDrawer-paper': {
      position: 'relative',
      whiteSpace: 'nowrap',
      width: drawerWidth,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      boxSizing: 'border-box',
      ...(!open && {
        overflowX: 'hidden',
        transition: theme.transitions.create('width', {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        width: theme.spacing(7),
        [theme.breakpoints.up('sm')]: {
          width: theme.spacing(9),
        },
      }),
    },
  }),
);

const defaultTheme = createTheme();

export default function Dashboard() {
  const [open, setOpen] = React.useState(true);
  const [module, setModule] = React.useState("Dashboard");

  const toggleDrawer = () => {
    setOpen(!open);
  };

  const changeModule = (newModule) => {
    setModule(newModule);
  }

  const logout = () => {
    const auth = getAuth();
    signOut(auth);
  };

  return (
    <ThemeProvider theme={defaultTheme}>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="absolute" open={open}>
          <Toolbar
            sx={{
              pr: '24px', // keep right padding when drawer closed
            }}
          >
            <IconButton
              edge="start"
              color="inherit"
              aria-label="open drawer"
              onClick={toggleDrawer}
              sx={{
                marginRight: '36px',
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <img src={logoImage} alt="logo" style={{ height: '50px', marginRight: '10px' }} />
            <Typography
              component="h1"
              variant="h6"
              color="inherit"
              noWrap
              sx={{ flexGrow: 1 }}
            >
              Murillo Family Observatory
            </Typography>
            <Button color="inherit" onClick={logout}>
              Logout
            </Button>
          </Toolbar>
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <Toolbar
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-end',
              px: [1],
            }}
          >
            <IconButton onClick={toggleDrawer}>
              <ChevronLeftIcon />
            </IconButton>
          </Toolbar>
          <Divider />
          <List component="nav">
            <MenuItem button key="Dashboard" selected={module === "Dashboard"} onClick={() => changeModule("Dashboard")}>
                <ListItemIcon><DashboardIcon /></ListItemIcon>
                <ListItemText primary={open ? "Dashboard" : ""} />
            </MenuItem>
            <MenuItem button key="Weather" selected={module === "Weather"} onClick={() => changeModule("Weather")}>
                <ListItemIcon><CloudIcon /></ListItemIcon>
                <ListItemText primary={open ? "Weather" : ""} />
            </MenuItem>
            <MenuItem button key="Lights" selected={module === "Lights"} onClick={() => changeModule("Lights")}>
                <ListItemIcon><WbSunnyIcon /></ListItemIcon>
                <ListItemText primary={open ? "Lights" : ""} />
            </MenuItem>
            <MenuItem button key="Power" selected={module === "Power"} onClick={() => changeModule("Power")}>
                <ListItemIcon><PowerIcon /></ListItemIcon>
                <ListItemText primary={open ? "Power" : ""} />
            </MenuItem>
            <MenuItem button key="Images" selected={module === "Images"} onClick={() => changeModule("Images")}>
                <ListItemIcon><ImageIcon /></ListItemIcon>
                <ListItemText primary={open ? "Images" : ""} />
            </MenuItem>
            <MenuItem button key="Webcam" selected={module === "Webcam"} onClick={() => changeModule("Webcam")}>
                <ListItemIcon><CameraAltIcon /></ListItemIcon>
                <ListItemText primary={open ? "Webcam" : ""} />
            </MenuItem>
            {/* Add other modules as needed */}
          </List>
        </Drawer>
        <Box
          component="main"
          sx={{
            backgroundColor: (theme) =>
              theme.palette.mode === 'light'
                ? theme.palette.grey[100]
                : theme.palette.grey[900],
            flexGrow: 1,
            height: '100vh',
            overflow: 'auto',
          }}
        >
          <Toolbar />
          <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
            <DisplayComponent module={module} />
          </Container>
        </Box>
      </Box>
    </ThemeProvider>
  );
}

function DisplayComponent({ module }) {
    switch (module) {
        case "Dashboard":
            return <DashboardModule />;
        case "Weather":
            return <WeatherModule />;
        case "Lights":
            return <LightModule />;
        case "Power":
            return <PowerModule />;
        case "Images":
            return <ImagesModule />;
        case "Webcam":
            return <WebcamModule />;
        default:
            return <DashboardModule />;
    }
}
