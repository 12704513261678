import React, { Component } from 'react';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import Typography from '@mui/material/Typography';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';


const WeatherTile = ({ getStatus }) => {
  // Determine if any status is red
  const isAnyStatusRed = Object.values(getStatus).some(status => status.color === 'red');

  // Choose icon based on the above condition
  const WeatherIcon = isAnyStatusRed ? HighlightOffIcon : CheckCircleOutlineIcon;

  return (
    <Paper style={{ padding: '20px', textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
      <Typography variant="h6" style={{ verticalAlign: 'middle' }}>
        <WeatherIcon style={{ color: isAnyStatusRed ? 'red' : 'green', verticalAlign: 'middle' }} />
        {' '}Weather
      </Typography>
      <Typography variant="h6">Clouds: <span style={{ color: getStatus.clouds.color }}>{getStatus.clouds.text}</span></Typography>
      <Typography variant="h6">Rain: <span style={{ color: getStatus.rain.color }}>{getStatus.rain.text}</span></Typography>
      <Typography variant="h6">Wind: <span style={{ color: getStatus.wind.color }}>{getStatus.wind.text}</span></Typography>
      <Typography variant="h6">Brightness: <span style={{ color: getStatus.light.color }}>{getStatus.light.text}</span></Typography>
      <Typography variant="h6">Humidity: <span style={{ color: getStatus.humidity.color }}>{getStatus.humidity.text}</span></Typography>
      <Typography variant="h6">Pressure: <span style={{ color: getStatus.pressure.color }}>{getStatus.pressure.text}</span></Typography>
    </Paper>
  );
};

// TelescopeTile component with a green icon (CheckCircleOutlineIcon)
const TelescopeTile = ({ id, data }) => (
  <Paper style={{ padding: '20px', textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
    <Typography variant="h6">
      <CheckCircleOutlineIcon style={{ color: 'green', verticalAlign: 'middle' }} />
      {' '}Telescope {id}
    </Typography>
    <Typography>Altitude: {data.altitude}</Typography>
    <Typography>Azimuth: {data.azimuth}</Typography>
    <Typography>Connected: {data.connected ? 'Yes' : 'No'}</Typography>
    <Typography>Tracking: {data.is_tracking ? 'Yes' : 'No'}</Typography>
    <Typography>RA: {data.right_ascension}</Typography>
    <Typography>Dec: {data.declination}</Typography>
  </Paper>
);

const DomeTile = ({ id, data }) => {
  const isShutterOpen = data.shutter_status !== "shutterClosed";
  const DomeIcon = isShutterOpen ? HighlightOffIcon : CheckCircleOutlineIcon;

  return (
    <Paper style={{ padding: '20px', textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
      <Typography variant="h6">
        <DomeIcon style={{ color: isShutterOpen ? 'red' : 'green', verticalAlign: 'middle' }} />
        {' '}Dome {id}
      </Typography>
      <Typography>Name: {data.dome_name}</Typography>
      <Typography>Azimuth: {data.dome_azimuth}</Typography>
      <Typography>Shutter Status: {data.shutter_status}</Typography>
      <Typography>Slewing: {data.slewing ? 'Yes' : 'No'}</Typography>
    </Paper>
  );
};

const PowerTile = ({ data }) => (
  <Paper style={{ padding: '20px', textAlign: 'center', marginBottom: '20px', marginLeft: '10px', marginRight: '10px' }}>
    <Typography variant="h6">
      <CheckCircleOutlineIcon style={{ color: 'green', verticalAlign: 'middle' }} />
      {' '}Power
    </Typography>
    <Typography>Estimated Charge Remaining: {data.EstimatedChargeRemaining}%</Typography>
    <Typography>Status: {data.Status}</Typography>
  </Paper>
);


class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      cdk24Data: { telescope: {}, dome: {} },
      cdk17Data: { telescope: {}, dome: {} },
      weather: {},
      power: {}, 
    };
  }

  componentDidMount() {
    this.fetchAllData();
    this.interval = setInterval(this.fetchAllData, 10000); // Set up an interval
  }

  componentWillUnmount() {
    clearInterval(this.interval); // Clear the interval
  }

  fetchAllData = () => {
    this.fetchData('api/cdk24status', 'cdk24Data');
    this.fetchData('api/cdk17status', 'cdk17Data');
    this.fetchWeatherData();
    this.fetchPowerData(); // Fetch power data
  };

  fetchData = async (url, stateKey) => {
    try {
      const response = await fetch(url);
      const data = await response.json();
      this.setState({ [stateKey]: data });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  fetchPowerData = async () => {
    try {
      const response = await fetch('api/battery_status');
      const data = await response.json();
      // Assuming you want to use the first item in the array
      const powerData = data[0]; // Adjust according to your needs
      this.setState({ power: powerData }); // Update state with fetched power data
    } catch (error) {
      console.error('Error fetching power data:', error);
    }
  };

  fetchWeatherData = async () => {
    try {
      const response = await fetch('api/get-weather');
      const data = await response.json();
      this.setState({ weather: data });
    } catch (error) {
      console.error('Error fetching weather data:', error);
    }
  };

  getCloudStatus = (cloudValue) => {
    if (cloudValue <= -5) return "Clear";
    if (cloudValue <= 0) return "Cloudy";
    if (cloudValue <= 30) return "Overcast";
    return "Unknown";
  };

  getRainStatus = (rainValue) => {
    if (rainValue >= 3200) return "Dry";
    if (rainValue >= 2800) return "Wet";
    if (rainValue >= 0) return "Rain";
    return "Unknown";
  };

  getWindStatus = (windValue) => {
    if (windValue <= 5) return "Calm";
    if (windValue <= 10) return "Windy";
    if (windValue <= 999) return "Very Windy";
    return "Unknown";
  };

  getBrightnessStatus = (lightValue) => {
    if (lightValue >= 2100) return "Dark";
    if (lightValue >= 6) return "Light";
    if (lightValue >= 0) return "Very Light";
    return "Unknown";
  };

  getHumidityStatus = (humidityValue) => {
    if (humidityValue <= 50) return "Dry";
    if (humidityValue <= 80) return "Normal";
    if (humidityValue <= 100) return "Humid";
    return "Unknown";
  };

  getPressureStatus = (pressureValue) => {
    if (pressureValue <= 980) return "Low";
    if (pressureValue <= 1030) return "Normal";
    if (pressureValue <= 1200) return "High";
    return "Unknown";
  };

  getStatusColor = (status) => {
    switch(status) {
      case 'Clear': case 'Dry': case 'Calm': case 'Dark': case 'Dry': case 'Low': return 'green';
      case 'Cloudy': case 'Wet': case 'Windy': case 'Light': case 'Normal': case 'Normal': return 'orange';
      case 'Overcast': case 'Rain': case 'Very Windy': case 'Very Light': case 'Humid': case 'High': return 'red';
      default: return 'black';
    }
  };

  getWeatherStatus = () => {
    const { weather } = this.state;
    return {
      clouds: {
        text: this.getCloudStatus(weather.clouds),
        color: this.getStatusColor(this.getCloudStatus(weather.clouds))
      },
      rain: {
        text: this.getRainStatus(weather.rain),
        color: this.getStatusColor(this.getRainStatus(weather.rain))
      },
      wind: {
        text: this.getWindStatus(weather.wind),
        color: this.getStatusColor(this.getWindStatus(weather.wind))
      },
      light: {
        text: this.getBrightnessStatus(weather.light),
        color: this.getStatusColor(this.getBrightnessStatus(weather.light))
      },
      humidity: {
        text: this.getHumidityStatus(weather.hum),
        color: this.getStatusColor(this.getHumidityStatus(weather.hum))
      },
      pressure: {
        text: this.getPressureStatus(weather.relpress),
        color: this.getStatusColor(this.getPressureStatus(weather.relpress))
      }
    };
  };

  render() {
    const { cdk24Data, cdk17Data, power } = this.state;
    const weatherStatus = this.getWeatherStatus();

    return (
      <div style={{ margin: '20px' }}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <WeatherTile getStatus={weatherStatus} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TelescopeTile id={1} data={cdk24Data.telescope} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DomeTile id={1} data={cdk24Data.dome} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <TelescopeTile id={2} data={cdk17Data.telescope} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <DomeTile id={2} data={cdk17Data.dome} />
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <PowerTile data={this.state.power} />
          </Grid>
        </Grid>
      </div>
    );
  }
}

export default Dashboard;
