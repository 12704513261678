import React from 'react';
import styled from '@emotion/styled';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';

// Styled components using @emotion
const StyledCard = styled(Card)({
  maxWidth: 345,
});

const StyledCardMedia = styled(CardMedia)({
  // ⚠️ object-fit is not supported by IE11.
  objectFit: 'cover',
  height: 140, // Added height here
});

class ImagesModule extends React.Component {
  constructor(props){
    super(props);
    this.state = {
      user: "Unknown",
    };
  }

  render() {
    return (
      <StyledCard>
        <StyledCardMedia
          component="img"
          image={require('../images/stock.jpg')} // Ensure the path is correct
          title="Stars"
        />
        <CardContent>
          <Typography gutterBottom variant="headline" component="h2">
            No images yet
          </Typography>
          <Typography component="p">
            When you take pictures using the telescope your images will be stored here
          </Typography>
        </CardContent>
        <CardActions>
          <Button size="small" color="primary">
            Share
          </Button>
          <Button size="small" color="primary">
            Learn More
          </Button>
        </CardActions>
      </StyledCard>
    );
  }
}

export default ImagesModule;
